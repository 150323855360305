import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import navIcon1 from "../assets/img/nav-icon1.svg";


export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="#"><img src={navIcon1} alt="Icon" /></a>
              
            </div>
            <p>
              <a href="privacy-policy.html">Privacy Policy</a> | 
              <a href="refund-policy.html">Refund Policy</a> | 
              <a href="terms-of-services.html">Terms of Services</a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}